<template>
  <div class="diy">
    <el-card>
      <div slot="header" class="flex flex-h-center relative">
        <h3 class="text-center">{{ $t('DIY模板') }}</h3>

        <el-popover style="position: absolute;right:0" placement="bottom-end" width="400" trigger="hover">
          <div style="" v-html="$t1(tip, 'content')"></div>
          <el-button type="danger" round size="mini" icon="el-icon-info" slot="reference">{{ $t('模板说明') }}</el-button>
        </el-popover>
      </div>
      <el-page-header :title="$t('返回')" class="margin-x" v-if="tag != 'index'" @back="setTag('index')"
        :content="$t(title)">
      </el-page-header>
      <div v-if="tag == 'index'">
        <div class="flex flex-between flex-v-center margin diy-card"
          style="background-image: linear-gradient(to right, #D3EAFF, #BED7F8);">
          <div class="flex flex-v-center margin-x">
            <img style="height: 100px;vertical-align: middle;" src="/web/image/diy/mine.png" />
            <div class="padding">
              <h3>Hello</h3>
              <h4 class="margin-t">{{ $store.state.user.nickName }} {{ $store.state.lang != 'en' ? $t('同学') : '' }}</h4>
              <h4 class="margin-t">{{ $t('欢迎使用DIY动态模板') }}！</h4>
            </div>
          </div>

          <div @click="setTag('mine')" class="flex diy-card padding margin-x" style="align-items: center;">
            <el-button class="margin-l" round plain icon="el-icon-right">{{ $t('我的模板') }}</el-button>
          </div>
        </div>
        <el-divider></el-divider>
        <div @click="setTag('DI')" class="diy-card card1 flex1 padding margin-x margin-lt flex flex-between">
          <div>
            <h4>Describe Image</h4>
            <h5 class="margin-t">{{ $t('动态模板，完美适配人工审核') }}</h5>
            <div class="margin-t"><el-button round size="small">{{ $t('开始') }}</el-button></div>
          </div>
          <img style="height: 100px;" src="/web/image/diy/diy-di.png" />
        </div>

        <div @click="setTag('RL')" class="diy-card margin-x card2 flex1 padding margin-lt flex flex-between">
          <div>
            <h4>Retell Lecture</h4>
            <h5 class="margin-t">{{ $t('动态模板，完美适配人工审核') }}</h5>
            <div class="margin-t"><el-button round size="small">{{ $t('开始') }}</el-button></div>
          </div>
          <img style="height: 100px;" src="/web/image/diy/diy-rl.png" />
        </div>
      </div>

      <div v-else-if="tag == 'mine'" class="margin-lt margin-x">
        <el-form label-position="left" :label-width="$store.state.lang == 'en' ? '140px' : '100px'" :model="filter">

          <el-form-item :label="$t('选择题型') + ':'">
            <el-radio-group @change="query_templates(1)" v-model="filter.tag">
              <el-radio-button border label="DI">Describe Image</el-radio-button>
              <el-radio-button border label="RL">Retell Lecture</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('模板类型') + ':'" v-if="filter.tag == 'DI'">
            <el-radio-group @change="query_templates(1)" v-model="filter.type">
              <el-radio-button v-for="(item, index) in ['数据类', '图片类', '流程类']" :key="index" border
                :label="$t(item)"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div v-loading="loading">
          <div v-for="(item, index1) in mineList" :key="index1" :class="{ 'bb': index1 < mineList.length - 1 }"
            class="diy-item on">
            <div class="flex flex-between flex-v-center" style="height:100%">
              <div>
                <h5>{{ $t2(item.name) }}</h5>

                <div class="flex flex-v-center margin-ts">
                  <div style="font-size: 12px;">{{
                    item.created_at }}</div>
                  <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </div>
              <div>
                <el-button size="mini" icon="el-icon-delete" @click="remove(item)" round type="primary" plain>{{
                  $t('删除') }}</el-button>
                <el-button size="mini" icon="el-icon-download" @click="to(item)" round type="primary">{{ $t('下载')
                  }}</el-button>
              </div>
            </div>
          </div>

          <el-empty v-if="mineList.length == 0" :description="$t('还没有数据哦') + '~'"></el-empty>
        </div>
      </div>

      <div v-else-if="tag == 'DI' || tag == 'RL'" class="padding">

        <el-steps :active="step" simple>
          <el-step :title="form.tag + ' ' + $t('模板')" icon="el-icon-edit-outline"></el-step>
          <el-step :title="$t('生成模板')" icon="el-icon-document-checked"></el-step>
        </el-steps>

        <div v-if="step == 1">
          <el-form label-position="top" label-width="100px" :model="form">
            <el-form-item :label="$t('选择模板类型') + ':'" v-if="form.tag == 'DI'">
              <el-radio-group @change="typeChange" v-model="form.type">
                <el-radio border label="数据类">{{ $t('数据类') }}</el-radio>
                <el-radio border label="图片类">{{ $t('图片类') }}</el-radio>
                <el-radio border label="流程类">{{ $t('流程类') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('选择模板框架') + ':'">
              <el-radio-group v-model="form.mode">
                <div class="flex flex-wrap" style="gap: 20px;">
                  <div>
                    <el-radio :label="2" border>{{ $t('1句introduction + 2句主体内容 + 1句conclusion') }}</el-radio>
                  </div>
                  <div>
                    <el-radio border :label="3">{{ $t('1句introduction + 3句主体内容 + 1句conclusion') }}</el-radio>
                  </div>
                  <div>
                    <el-radio border :label="4">{{ $t('1句introduction + 4句主体内容 + 1句conclusion(推荐)') }}</el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('选择衔接词套组') + ':'">
              <el-radio-group v-loading="loading" @change="connectiveChange" v-model="form.connective">
                <div class="flex flex-wrap" style="gap: 20px;">
                  <div v-for="(item, index) in list" :sapn="16" :key="index">
                    <el-radio border :label="item">{{ item.split(',').join(', /') }},</el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="flex flex-h-center">
            <el-button type="primary" round @click="step++">{{ $t('生成模板') }}</el-button>
          </div>
        </div>
        <div v-else-if="step == 2">
          <div class="margin-t">
            <div>
              {{ this.form.tag + '/' }} {{ this.form.type ? (this.$t(this.form.type) + '/') : '' }}{{ '1+' +
                this.form.mode +
                '+1'
              }}
            </div>

            <div>
              <div class="card  padding margin-t">
                <div style="font-weight: bold;">
                  Introduction:
                </div>

                <div v-if="selectedSentences[0]" class="margin-ts">
                  {{ $t2(selectedSentences[0]) }}
                </div>

                <div class="flex" style="justify-content: flex-end;">
                  <el-dropdown @command="setSentence">
                    <div class="st pointer">{{ $t('内容替换') }}<i class="el-icon-arrow-down el-icon--right"></i></div>
                    <el-dropdown-menu slot="dropdown" style="max-height: 50vh;overflow-y: scroll">
                      <el-dropdown-item :class="{ 'row-checked': selectedSentences[0] == row }" :command="'0#' + row"
                        v-for="(row, index1) in sentences[0]" :key="index1">{{ index1 + 1 }}. {{ $t2(row)
                        }}<i style="font-size: 20px;" v-if="selectedSentences[0] == row"
                          class="el-icon-check"></i></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                </div>
              </div>

              <div v-for="index in form.mode" :key="index" class="card  padding margin-t">
                <div style="color: #ff0000;font-weight: bold;">
                  {{ $t('主体句') }}{{ index }}
                </div>

                <div class="flex flex-v-center">
                  <div style="font-weight: bold;">
                    【{{ selectedConnectives[index - 1] }}, 】
                  </div>

                  <el-dropdown @command="setConnective">
                    <div class="st pointer">{{ $t('替换') }}<i class="el-icon-arrow-down el-icon--right"></i></div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :class="{ 'row-checked': selectedConnectives[index - 1] == row }"
                        :command="(index - 1) + '#' + row" v-for="(row, index1) in connectives[index - 1]"
                        :key="index1">{{ $t2(row)
                        }}<i v-if="selectedConnectives[index - 1] == row" style="font-size: 20px;"
                          class="el-icon-check"></i></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div v-if="selectedSentences[index]" class="margin-ts">
                  {{ $t2(selectedSentences[index]) }}
                </div>

                <div class="flex" style="justify-content: flex-end;">
                  <el-dropdown @command="setSentence">
                    <div class="st pointer">{{ $t('内容替换') }}<i class="el-icon-arrow-down el-icon--right"></i></div>
                    <el-dropdown-menu slot="dropdown" style="max-height: 50vh;overflow-y: scroll;">
                      <el-dropdown-item :class="{ 'row-checked': selectedSentences[index] == row }"
                        :command="index + '#' + row" v-for="(row, index1) in sentences[index]" :key="index1">{{ index1 +
                          1 }}. {{ $t2(row)
                        }}<i v-if="selectedSentences[index] == row" style="font-size: 20px;"
                          class="el-icon-check"></i></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="card  padding margin-t">
                <div style="font-weight: bold;">
                  Conclusion:
                </div>
                <div v-if="selectedSentences[5]" class="margin-ts">
                  {{ $t2(selectedSentences[5]) }}
                </div>

                <div class="flex" style="justify-content: flex-end;">
                  <el-dropdown @command="setSentence">
                    <div class="st pointer">{{ $t('内容替换') }}<i class="el-icon-arrow-down el-icon--right"></i></div>
                    <el-dropdown-menu slot="dropdown" style="max-height: 50vh;overflow-y: scroll;">
                      <el-dropdown-item :class="{ 'row-checked': selectedSentences[5] == row }" :command="'5#' + row"
                        v-for="(row, index1) in sentences[5]" :key="index1">{{ index1 + 1 }}. {{ $t2(row)
                        }}<i v-if="selectedSentences[5] == row" style="font-size: 20px;"
                          class="el-icon-check"></i></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-h-center margin-lt">
            <el-button type="primary" class="margin-r" plain round @click="step--">{{ $t('上一步') }}</el-button>
            <el-button type="primary" class="margin-l" @click="save" round>{{ $t('确定生成模板') }}</el-button>
          </div>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      form: { tag: 'DI', type: '数据类', mode: 2, connective: '' },
      list: [],
      tag: 'index',
      tip: {},
      mineList: [],
      title: '',
      activeTag: 'DI',
      selectedSentences: [],
      selectedConnectives: [],
      sentences: [],
      connectives: [],
      customName: '',
      filter: { tag: 'DI', type: '数据类', page: 1 },
      last_page: 1,
      loading: false,
    };
  },

  created() {
    if (this.$route.query.tag) {
      this.setTag(this.$route.query.tag)
    }
    this.query_tip()
  },




  watch: {
    $route() {
      this.setTag(this.$route.query.tag || 'index')
    },
    step(val, oldVal) {
      if (val == 2 && oldVal == 1) {
        this.get_connectives()
        this.get_sentences()
      }
    }
  },

  computed: {
  },

  methods: {
    remove(item) {
      this.$confirm('', this.$t('确认删除此模板') + '?', {
        showCancle: false,
        center: true,
        confirmButtonClass: "confirm-btn",
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'primary'
      }).then(() => {
        this.$http.post("/api/diy/template/remove", { id: item.id }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('删除成功') + '!'
          });
          this.query_templates()
        })
      }).catch(() => {

      });
    },
    to(item) {
      window.open(this.$host + '/api/diy/download?id=' + item.id + '&token=' + localStorage.getItem('token') + '&lang=' + this.$store.state.lang)
    },
    save() {
      let items = []
      items.push(this.form.tag)
      if (this.form.type) items.push(this.$t(this.form.type))
      items.push(`1+${this.form.mode}+1`)
      const name = items.join('/')
      this.$prompt(this.$t('确定生成后不可再次修改'), this.$t('保存模板'), {
        inputValue: '',
        center: true,
        roundButton: true,
        inputPlaceholder: name,
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
      }).then(({ value }) => {
        console.log(value)
        let connectives = this.selectedConnectives.slice(0, this.form.mode)
        let sentences = [this.selectedSentences[0], ...this.selectedSentences.slice(1, 1 + this.form.mode), this
          .selectedSentences[5]
        ]

        this.$http.post("/api/diy/template/save", {
          name: value || name,
          mode: this.form.mode,
          connectives,
          sentences,
          tag: this.form.tag,
          type: this.form.type
        }).then(() => {
          this.$message.success("保存成功")
          this.tag = 'mine'
          this.step = 1
          this.filter.tag = this.form.tag
          this.filter.type = this.form.type
          this.query_templates(1)
        })
      }).catch(() => {

      });

    },
    typeChange() {
      this.get_connective_groups()
    },
    get_connectives() {
      this.loading = true
      this.$http.post("/api/diy/connectives", {
        type: this.form.type,
        tag: this.form.tag
      }).then((connectives) => {
        this.connectives = connectives.map(val => val.split('\n'))
        this.loading = false
        this.$forceUpdate()
      })
    },
    setSentence(val) {
      let items = val.split('#')
      this.selectedSentences[Number(items[0])] = items[1]
      this.$forceUpdate()
    },

    setConnective(val) {
      let items = val.split('#')
      this.selectedConnectives[Number(items[0])] = items[1]
      this.$forceUpdate()
    },
    connectiveChange(val) {
      this.selectedConnectives = val.split(',')
    },
    get_connective_groups() {
      this.loading = true
      this.$http.post("/api/diy/connective/groups", {
        type: this.form.type,
        tag: this.form.tag
      }).then((list) => {
        this.list = list
        this.form.connective = this.list[0]
        this.selectedConnectives = this.form.connective.split(',')
        this.loading = false
      })
    },
    get_sentences() {
      this.$http.post("/api/diy/sentences", {
        tag: this.form.tag,
        type: this.form.type,
      }).then((sentences) => {
        this.sentences = sentences.map(val => val.split('\n'))
        this.selectedSentences = this.sentences.map((val) => {
          return val[this.randIndex(0, val.length - 1)]
        })
      })
    },
    randIndex(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    query_templates(page) {
      if (this.filter.tag == 'RL') this.filter.type = ''
      this.loading = true
      if (page) this.filter.page = page
      if (this.filter.page == 1) this.mineList = []
      this.$http.post("/api/diy/template/list", this.filter).then((res) => {
        this.mineList.push(...res.data)
        this.last_page = res.last_page
        this.loading = false
      })
    },
    setTag(tag) {
      this.tag = tag;
      if (this.tag == 'mine') {
        this.title = "我的模板"
        if (this.mineList.length == 0) this.query_templates()
      } else if (this.tag == 'DI') {
        this.title = "Describe Image"
        this.form.type = '数据类'
      } else if (this.tag == 'RL') {
        this.title = "Retell Lecture"
        this.form.type = ''
      }
      if (this.tag == 'DI' || this.tag == 'RL') {
        this.step = 1
        this.form.tag = this.tag
        this.get_connective_groups()
      }
    },

    query_tip() {
      this.$http.post("/api/diy/tip").then((tip) => {
        this.tip = tip
      })
    },

    prev(item) {
      if (item.page == 1) return;
      item.page--;
    },

    create() {
      this.centerDialogVisible = false;
      window.open(this.$host + "/api/pdf/" + this.item.uuid);
    },
  },
};
</script>
<style scoped>
.document>>>.el-card__header {
  padding: 10px 20px;
}

.diy-card {
  border-radius: 10px;
  cursor: pointer;
}

.diy-card.card1 {
  background-image: linear-gradient(to right, #FFEDC7, #FFEABE);
}

.diy-card.card2 {
  background-image: linear-gradient(to right, #A4D2D9, #84C5CF);
}


.card {
  background-color: #f9f9f9;
  border-radius: 10rpx;
}

.row-checked {
  background-color: #fdf6ec;
  color: #e6a13c;
}

.diy-item {
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.diy-item.bb {
  margin-bottom: 15px;
}

.diy-item:hover,
.diy-item.on {
  border: 1px solid #e6a13c;
  color: #e6a13c;
}

.diy>>>.el-page-header__content {
  flex: 1;
  text-align: center;
  position: absolute;
  width: 100%;
}

.diy>>>.el-page-header {
  position: relative;
}

.diy>>>.el-page-header__left {
  position: relative;
  z-index: 10;
}
</style>
